<template>
  <div class="matchCo">
    <Header
      back="返回"
      title="查新检测"
      index="首页"
      titleOne="科创模块"
      titleTwo="知识产权"
      titleThree="查新检测"
      beforeTitle="新增/编辑"
    />
    <div class="content">
      <h1>查新检测信息</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="委托人（合作客户）" prop="company_name">
          <el-autocomplete
            class="inline-input"
            v-model="form.company_name"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            placeholder="请输入委托人"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="项目名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="委托日期">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.entrust_time"
            style="width: 100%"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="交付日期">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.deliver_time"
            style="width: 100%"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="机构">
          <el-input v-model="form.organization" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="报告编号">
          <el-input v-model="form.report_no" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="form.type" placeholder="请选择类型">
            <el-option label="查新" :value="1">查新</el-option>
            <el-option label="检测" :value="2">检测</el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit()" :disabled="isDisable">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      isDisable: false,
      labelPosition: "top",
      form: {
        company_name: '',//委托人
        name: '',//项目名称--必填
        com_id: '',//企业id--必填
        organization: '',//机构
        entrust_time: '',//委托日期
        deliver_time: '',//交付日期
        report_no: '',//报告编号
        type: "",//查新检测类型 1查新 2检测
      },
      formRule: {
        name: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: "blur",
          },
        ],
        company_name: [
          {
            required: true,
            message: "请输入委托人",
            trigger: "blur",
          },
        ],
        // patent_type: [
        //   {
        //     required: true,
        //     message: "请选择专利类型",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  created () {
    console.log(this.$route.query.id)
  },
  mounted () {
    if (this.$route.query.id) {
      this.axios
        .get("/api/novelty/view", {
          params: {
            id: this.$route.query.id,
          },
        })
        .then((res) => {
          this.form = res.data;
        });
    }
  },
  methods: {
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      console.log(item, 555);
      this.form.com_id = item.id;
      this.form.company_name = item.value;
    },
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/novelty/store", this.form)
            .then((res) => {
              this.$router.push('/patent/index');
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.btnLoading = false
              this.isDisable = true;
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message({
            type: 'warning',
            message: '请输入必填项',
          })
        }
      });
    },
  },
};
</script>

<style>
.matchCo .el-form-item__label {
  width: 100% !important;
  text-align: left;
}

.matchCo .el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.matchCo .el-select {
  width: 100%;
}

.matchCo .el-checkbox {
  float: left;
  color: #909399;
}
.matchCo ul li {
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.matchCo ul li span {
  color: #909399;
}
.matchCo .el-radio-group {
  float: right;
}
.matchCo h5 {
  font-size: 16px;
  color: #cbcfe3;
  font-weight: 400;
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.el-form-item__label {
  color: #404040;
}
</style>
